<template>
    <div>
        <div>
            <div style="float:right">
                <select-hospital v-model="form.hospital_id" style="margin-right:10px" @input="getData" />
                <router-link to="/main/shihua/scheduling/form">
                    <el-button type="primary" size="small">排期</el-button>
                </router-link>
            </div>
            <el-page-header @back="$router.go(-1)" content="体检排期表" />
        </div>
        <el-calendar :first-day-of-week="7" style="margin-top:20px">
            <template slot="dateCell" slot-scope="{date, data}">
                <div :class="data.isSelected ? 'active' : ''" @click="selectDate(date)" style="height: 100%">
                    {{ data.day.split('-').slice(1).join('-') }}
                    <div v-if="dates[date.format('yyyy-MM-dd')]" class="active">
                        体检人数({{ dates[date.format('yyyy-MM-dd')].sum }})</div>
                    <div v-else></div>
                </div>
            </template>
        </el-calendar>
    </div>
</template>

<script>
export default {
    name: 'enterprise-shihua-scheduling',
    path: '/main/enterprise/shihua/scheduling',
    data() {
        return {
            dialogVisible: false,
            form: {},
            tableData: [],
            dates: {
                '2023-03-12': {
                    count: 22
                }
            }
        }
    },
    methods: {
        selectDate(e) {
            // let strDay = e.format('yyyy-MM-dd')
            // this.form.date = strDay
            // this.dialogVisible = true
            this.$router.push({ name: 'enterprise-shihua-scheduling-form', params: { date: e.format('yyyy-MM-dd') } })
        },
        filterHandler(value, row, column) {
            const property = column['property']
            return row[property] === value
        },
        async submit() {
            console.log(this.form)
            // const resp = await this.$http.post(`/worksheet`, this.form)
            // if (resp.data.state == 200) {
            //     this.$message.success("保存成功")
            //     this.getData()
            // }
            this.dates[this.form.date] = {
                count: this.form.users.length
            }
            this.dialogVisible = false
            this.$forceUpdate()
        },
        handleSelectionChange(val) {
            this.form.users = val
        },
        async getData() {
            this.loading = true
            const resp = await this.$http.get('/enterprise/shihua/scheduling/calendar', { params: this.form })
            if (!resp.data) {
                this.$message.error(resp.data.msg)
            } else {
                this.dates = resp.data.data
            }
            this.loading = false
        }
    },
    mounted() {
        this.getData()
    }
}
</script>

<style scoped>
:deep(.el-table .el-table__cell) {
    padding: 2px 0;
}
</style>